<template>
  <div class="wrapper">
<!--    <div-->
<!--        id="contactUs2Map"-->
<!--        class="map"-->
<!--    />-->
    <div class="main main-raised">
      <div class="section section-contact">
        <div class="container">
          <div class="md-layout">

<!--            <div-->
<!--                id="cerberusMap"-->
<!--                class="map"-->
<!--            />-->

            <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">

              <h2 class="title mt-0">
                Send us a message
              </h2>
              <p class="description">
                You can contact us with inquiries about our service. We'll
                get in touch with you as soon as possible.
                <br>
                <br>
              </p>


              <form>
<!--                <md-field>-->
<!--                  <label>Your Name</label>-->
<!--                  <md-input-->
<!--                      v-model="name"-->
<!--                      type="text"-->
<!--                  />-->
<!--                </md-field>-->
<!--                <md-field>-->
<!--                  <label>Email Adress</label>-->
<!--                  <md-input-->
<!--                      v-model="email"-->
<!--                      type="email"-->
<!--                  />-->
<!--                </md-field>-->
<!--                <md-field>-->
<!--                  <label>Phone</label>-->
<!--                  <md-input-->
<!--                      v-model="phone"-->
<!--                      type="number"-->
<!--                  />-->
<!--                </md-field>-->
<!--                <md-field>-->
<!--                  <label>Your message</label>-->
<!--                  <md-textarea v-model="textarea" />-->
<!--                </md-field>-->
                <div class="submit text-center mt-3">
                  <a href="mailto:info@boostem.io">
                    <md-button
                        class="md-primary md-round"
                        @click="trackEmail()"
                    >
                        Contact Us
                    </md-button>
                  </a>
                </div>
              </form>
            </div>

<!--            <div-->
<!--                class="md-layout-item md-size-50 md-small-size-100 d-flex-center"-->
<!--            >-->

<!--            </div>-->
<!--              </form>-->
<!--              </div>-->

            <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
              <info-areas
                  class="pt-0"
                  info-horizontal
                  icon-color="primary"
                  icon="pin_drop"
              >
                <h4
                    slot="title"
                    class="info-title"
                >
                  Boost 'Em
                </h4>
                <div
                    slot="content"
                    class="description"
                >
                  <p>
                    Birmingham, AL <br>
                    Chattanooga, TN <br>
                    Austin, TX <br>
                  </p>
                </div>
              </info-areas>

              <md-card class="md-card-form-horizontal md-card-plain">
                <md-card-content>
                  <mailchimp-subscribe
                    url="https://boostem.us21.list-manage.com/subscribe/post-json"
                    user-id="34dc523b660d899b420678f53"
                    list-id="486a1c9958"
                    @error="onError"
                    @success="onSuccess"
                  >
                    <template v-slot="{ subscribe, setEmail, error, success, loading }">
                      <form
                        @submit.prevent="subscribe"
                      >
                        <div class="md-layout">
                          <div
                            class="md-layout-item md-size-66 md-xsmall-size-100 md-medium-size-50"
                          >

                            <md-field class="md-form-group">
                              <md-icon>mail</md-icon>
                              <md-input
                                type="email"
                                @input="setEmail($event)"
                                placeholder="Join our waiting list"
                              />
                            </md-field>
                          </div>
                          <div
                            class="md-layout-item md-size-33 md-medium-size-50 md-xsmall-size-100"
                          >
                            <md-button
                              type="submit"
                              class="md-primary md-round md-block"
                              @click="trackMailChimp(); modalVisible = true"
                            >
                              Join
                            </md-button>
                            <div
                              class="modal modal-bg"
                              :class="{ show: modalVisible }"
                            >
                              <modal
                                v-if="modalVisible"
                                @close="modalVisible = false"
                              >
                                <template slot="header">
                                  <h4 class="modal-title">
                                    Boost 'Em Waitlist
                                  </h4>
                                  <md-button
                                    class="md-simple md-close md-just-icon md-round modal-default-button"
                                    @click="modalVisible = false"
                                  >
                                    <md-icon>clear</md-icon>
                                  </md-button>
                                </template>

                                <template slot="body">
                                  <div
                                    v-if="error"
                                  >
                                    {{ error }}
                                  </div>
                                  <div
                                    v-if="success"
                                  >
                                    <p>You've successfully subscribed to our mailing list!</p>
                                  </div>
                                  <div
                                    v-if="loading"
                                  >
                                    <p>Loading…</p>
                                  </div>

                                </template>

                                <template slot="footer">
                                  <md-button
                                    class="md-danger md-simple"
                                    @click="modalVisible = false"
                                  >
                                    Close
                                  </md-button>
                                </template>
                              </modal>
                            </div>
                          </div>
                        </div>
                        <!--                      </div>-->
                      </form>
                    </template>
                  </mailchimp-subscribe>
                </md-card-content>
            </md-card>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {InfoAreas, Modal} from "@/components";
import { postEmail, emailCheck } from "@/plugins/axiosApi";
// import Mixins from "@/plugins/basicMixins";
import MailchimpSubscribe from "vue-mailchimp-subscribe";

export default {
  name: "BoostEmContactUs",
  components: {
    InfoAreas,
    Modal,
    MailchimpSubscribe
  },
  bodyClass: "contact-us",
  // mixins: [Mixins.InitMap],
  data() {
    return {
      modalVisible: false,
      // name: null,
      // email: null,
      // phone: null,
      textarea: null,
      emailAddress: null,
      success: false
    };
  },
  methods: {
    async sendEmail() {
      let check = await emailCheck(this.emailAddress);

      if (!check) {
        this.textarea = 'Please enter a valid email to subscribe'
      }
      else {
        let result = await postEmail(this.emailAddress);

        // console.log(result);
        this.success = result;

        if (this.success){
          this.textarea = 'You\'ve successfully subscribed to our waiting list!'
        }
        else {
          this.textarea = 'Waiting list subscription failed. Please try again later.'
        }
      }

      this.modalVisible = true;
    },
    trackMailChimp() {
      this.$gtag.event('Subscribe', {
        'event_category': 'mailing_subscription',
        'event_label': 'Subscribe to Waitlist'
      })
    },
    trackEmail() {
      this.$gtag.event('Send_email', {
        'event_category': 'general',
        'event_label': 'Send Email'
      })
    },
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Contact Us segment of landing page for CerberusLabs.tech'
      }
    ],
  }
};
</script>

<style scoped lang="scss">
.mt-3 {
  margin-top: 1.5rem;
}
.map {
  center: { lat: -25.344; lng: 131.031 };
}
</style>