<template>
  <footer
      class="footer"
      :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
      :data-background-color="backgroundColor"
  >
    <template >
      <div class="container flex-direction-row">
      <div class="copyright">
        &copy; {{ year }}

        <a
            class="footer-brand"
            href="/"
            @click="trackHome()"
        >Boost 'Em</a>
      </div>
        <ul>
<!--          <li>-->
<!--            <a-->
<!--                href=""-->
<!--                @click="trackApp()"-->
<!--            >-->
<!--              App.CerberusLabs-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a-->
<!--                href="/blog"-->
<!--                @click="trackBlog()"-->
<!--            >-->
<!--              Blog-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="licenses">-->
<!--              Licenses-->
<!--            </a>-->
<!--          </li>-->
        </ul>

        <ul class="social-buttons">
          <li>
            <md-button
              href="https://www.tiktok.com/@boostemofficial"
              target="_blank"
              class="md-just-icon md-simple"
              @click="trackTikTok()"
            >
              <img src="../../assets/img/tiktok-square-color-icon.png"/>
            </md-button>
            <md-button
                href="https://twitter.com/boostemofficial"
                target="_blank"
                class="md-just-icon md-simple"
                @click="trackTwitter()"
            >
              <img src="../../assets/img/x-social-media-round-icon.png"/>
            </md-button>
          </li>
        </ul>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  name: "BoostEmFooter",
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    trackHome() {
      this.$gtag.event('Navigate_Home', {
        'event_category': 'footer_nav',
        'event_label': 'Go to Home'
      })
    },
    // trackBlog() {
    //   this.$gtag.event('Click', {
    //     'event_category': 'footer_nav',
    //     'event_label': 'Go to Blog'
    //   })
    // },
    trackTikTok() {
      this.$gtag.event('Navigate_TikTok', {
        'event_category': 'footer_nav',
        'event_label': 'Go to TikTok'
      })
    },
    trackTwitter() {
      this.$gtag.event('Navigate_Twitter', {
        'event_category': 'footer_nav',
        'event_label': 'Go to Twitter'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.footer-brand {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}

</style>
