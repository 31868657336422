import axios from "axios";
import { SUBSCRIPTION_KEY } from "@/API_KEY";

export async function postEmail(email) {
  let success = null;

  const result = await axios({
    method: 'post',
    url: `https://boostem-testing.azurewebsites.net/api/v1/Subscriptions/AddEmailToMailingList?emailAddress=${email}`,
    data: null,
    headers: {
      'Authorization': 'ClientId ' + SUBSCRIPTION_KEY
    }
  });

  if (result.status === 200) {
    success = true;
    // console.log(success);
    return success;
  }
  else {
    success = false;
    // console.log(success);
    return success;
  }
}

export async function emailCheck(email) {
  let validEmail = false;
  await axios
    .get(`https://disify.com/api/email/${email}`)
    .then((response) => {
      // console.log(response);
      validEmail = response.data.dns;
      // console.log(validEmail);
    });

  if (validEmail)
  {
    return true;
  }
  else {
    return false;
  }
}