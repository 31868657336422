<template>
  <div class="wrapper">
    <parallax
        class="page-header header-filter"
        parallax-active="false"
        :style="headerStyle"
    >

      <div class="container">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-80 md-small-size-100 mx-auto text-center"
          >
            <md-card class="md-card md-card-signup">
              <md-card-content>
                <h2 class="card-title text-center">
                  Join Our Waitlist
                </h2>
                      <div class="md-layout">
                        <div class="md-layout-item md-xlarge-size-100 md-small-size-100">
                          <info-areas
                              info-horizontal
                              icon-color="primary"
                              icon="email"
                          >
                            <h4
                                slot="title"
                                class="info-title"
                            >
                              Stay Updated!
                            </h4>
                            <div
                                slot="content"
                                class="description"
                            >
                              <p>
                                Join our waitlist, and receive our periodic content & updates,
                                including testing release dates and news related to Boost 'Em.
                              </p>
                            </div>
                          </info-areas>
                        </div>
                      </div>

                      <div
                          class="md-layout-item md-size-80 md-small-size-100 mx-auto text-center"
                      >
                        <mailchimp-subscribe
                          url="https://boostem.us21.list-manage.com/subscribe/post-json"
                          user-id="34dc523b660d899b420678f53"
                          list-id="486a1c9958"
                          @error="onError()"
                          @success="onSuccess()"
                        >
                          <template v-slot="{ subscribe, setEmail, error, success, loading }">
                            <form
                              @submit.prevent="subscribe"
                            >
                              <div class="md-layout">
                                <div class="md-layout-item md-size-100 md-medium-size-100 md-xsmall-size-100">
                                  <md-field class="md-form-group">
                                    <md-icon>mail</md-icon>
                                    <md-input
                                      type="email"
                                      @input="setEmail($event)"
                                      placeholder="Enter your email here"
                                    />
                                  </md-field>
                                </div>
                                <div class="md-layout-item md-size-100 md-medium-size-100 md-xsmall-size-100">
                                  <md-button
                                    type="submit"
                                    class="md-primary md-round md-block"
                                    @click="trackMailChimp(); modalVisible = true"
                                  >
                                    Join
                                  </md-button>
                                  <div
                                    class="modal modal-bg"
                                    :class="{ show: modalVisible }"
                                  >
                                    <modal
                                      v-if="modalVisible"
                                      @close="modalVisible = false"
                                    >
                                      <template slot="header">
                                        <h4 class="modal-title">
                                          Boost 'Em Waitlist
                                        </h4>
                                        <md-button
                                          class="md-simple md-close md-just-icon md-round modal-default-button"
                                          @click="modalVisible = false"
                                        >
                                          <md-icon>clear</md-icon>
                                        </md-button>
                                      </template>

                                      <template slot="body">
                                        <div
                                          v-if="error"
                                        >
                                          <p>{{ error }}</p>
                                        </div>
                                        <div
                                          v-if="success"
                                        >
                                          <p>You've successfully subscribed to our mailing list!</p>
                                        </div>
                                        <div
                                          v-if="loading"
                                        >
                                          <p>Loading…</p>
                                        </div>

                                      </template>

                                      <template slot="footer">
                                        <md-button
                                          class="md-danger md-simple"
                                          @click="modalVisible = false"
                                        >
                                          Close
                                        </md-button>
                                      </template>
                                    </modal>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </template>
                        </mailchimp-subscribe>
                      </div>

              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>

      <footer class="footer">
        <div class="container flex-direction-row">
          <nav>
            <ul>
              <li>
                <a
                    href="/"
                    @click="trackHome()"
                >
                  Home
                </a>
              </li>
<!--              <li>-->
<!--                <a-->
<!--                    href="/blog"-->
<!--                    @click="trackBlog()"-->
<!--                >-->
<!--                  Blog-->
<!--                </a>-->
<!--              </li>-->
            </ul>
          </nav>
          <div class="copyright">
            <a
                href="https://boostem.io/"
                @click="trackHome()"
            >
            &copy; {{ year }} Boost 'Em
            </a>
          </div>
        </div>
      </footer>
    </parallax>
  </div>
</template>

<script>
import { InfoAreas, Modal } from "@/components";
import Mixins from "@/plugins/basicMixins";
import MailchimpSubscribe from "vue-mailchimp-subscribe";
import { emailCheck, postEmail } from "@/plugins/axiosApi";

export default {
  name: 'BoostEmSubscribe',
  components: {
    InfoAreas,
    Modal,
    MailchimpSubscribe
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "signup-page",
  data() {
    return {
      modalVisible: false,
      boolean: null,
      textarea: null,
      emailAddress: null,
      success: false,
      image: require("@/assets/img/boostEm_bg1.png"),
      year: new Date().getFullYear(),
      inputs: [
        {
          icon: "face",
          name: "Name",
          type: "text"
        },

        {
          icon: "email",
          name: "Email",
          type: "email"
        },
      ]
    };
  },
  methods: {
    async sendEmail() {
      let check = await emailCheck(this.emailAddress);

      if (!check) {
        this.textarea = 'Please enter a valid email to subscribe'
      }
      else {
        let result = await postEmail(this.emailAddress);

        // console.log(result);
        this.success = result;

        if (this.success){
          this.textarea = 'You\'ve successfully subscribed to our waiting list!'
        }
        else {
          this.textarea = 'Waiting list subscription failed. Please try again later.'
        }
      }

      this.modalVisible = true;
    },
    trackHome() {
      this.$gtag.event('Navigate_Home', {
        'event_category': 'waitlist_footer',
        'event_label': 'Go to Home'
      })
    },
    trackBlog() {
      this.$gtag.event('Navigate_Blog', {
        'event_category': 'waitlist_footer',
        'event_label': 'Go to Blog'
      })
    },
    trackMailChimp () {
      this.$gtag.event('Subscribe', {
        'event_category': 'mailing_subscription',
        'event_label': 'Subscribe to Waitlist'
      })
    },
    onSuccess() {
    },
    onError() {
    }
  },
  metaInfo: {
    title: 'Join Our List! | Boost \'Em',
    meta: [
      {
        name: 'description',
        content: 'Waitlist subscription page for Boost \'Em'
      }
    ],
  },
};
</script>
<style lang="scss"></style>
