// =========================================================
// * Boost 'Em - v1.0.1
// =========================================================
//
// * Copyright 2022 Sports Software Corp
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be
//    included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
import VueGtagPlugin from "vue-gtag";
import VueMeta from 'vue-meta';

import MaterialKit from "./plugins/material-kit";

Vue.config.productionTip = false;

Vue.use(MaterialKit);

Vue.use(VueGtagPlugin, {
  appName: 'Boost \'Em',
  pageTrackerScreenviewEnabled: true,
  config: {
    id: 'G-NQQ6SGHJ22',
  }
}, router);

Vue.use(VueMeta, {
  keyName: 'metaInfo'
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
