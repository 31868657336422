<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    >
      <div class="container">
        <div class="space-100"/>
        <div class="md-card-header-image">
          <img src="../../assets/img/boostEm_white.png">
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">

<!--            <h2 class="title">-->
<!--&lt;!&ndash;              Welcome to boostEm&ndash;&gt;-->
<!--              Be your favorite athletes' Booster!-->
<!--            </h2>-->
<!--            <h5>-->
<!--&lt;!&ndash;              Be your team's Booster!<br><br>&ndash;&gt;-->
<!--              Welcome to the simplified platform for college athletes'-->
<!--              use of Name, Image, & Likeness rules-->
<!--              <br><br>-->
<!--              Send payments to your favorite college athletes &-->
<!--              interact with them to show them your support-->
<!--            </h5>-->
<!--            <br>-->

            <h2 class="title">
              <!--              Welcome to boostEm-->
              A new way to support your favorite collegiate athletes
            </h2>
            <h5>
              <!--              Be your team's Booster!<br><br>-->
<!--              Welcome to the simplified platform for college athletes'-->
<!--              use of Name, Image, & Likeness rules-->
<!--              <br><br>-->
<!--              Send payments to your favorite college athletes &-->
<!--              interact with them to show them your support-->

              Introducing a new platform to interact with and fund
              collegiate athletes. With Boost 'Em, you will be able to send
              messages & funding to the athletes you most care
              about.
              <br><br>
<!--              Show your support and send funds to receive different-->
<!--              levels of interaction responses based on your level of contribution.-->
<!--              Get to directly engage with the players from your favorite teams-->
<!--              and sports!-->

            </h5>
<!--            <br>-->

<!--            <md-button-->
<!--                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"-->
<!--                class="md-success md-lg"-->
<!--                target="_blank"-->
<!--            >-->
<!--              <i class="fas fa-play" /> Watch Demo-->
<!--            </md-button>-->
<!--            <md-button-->
<!--                type="submit"-->
<!--                href="/subscribe"-->
<!--                class="md-primary md-lg"-->
<!--                @click="trackAppVisit()"-->
<!--            >-->
<!--              Join our waitlist-->
<!--            </md-button>-->
          </div>
        </div>
        <md-button
          type="submit"
          href="/subscribe"
          class="md-primary md-lg md-absolute button-outline"
          @click="trackAppVisit()"
        >
          join our waitlist
        </md-button>
        <div class="space-110"/>
      </div>
    </parallax>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  name: "BoostEmLanding",
  components: {
    // ProfileCard
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "landing-page",
  data() {
    return {
      image: require("@/assets/img/boostEm_bg1.png"),
      name: null,
      email: null,
      message: null,
      cardProfile3: {
        // cardProfile1: require("@/assets/img/faces/card-profile1-square.jpg"),
        // cardProfile2: require("@/assets/img/faces/card-profile6-square.jpg"),
        // cardProfile3: require("@/assets/img/faces/card-profile4-square.jpg"),
        // cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg")
      }
    };
  },
  methods: {
    trackAppVisit() {
      this.$gtag.event('Subscribe', {
        'event_category': 'Subscription',
        'event_label': 'Go to Join Waitlist'
      })
    }
  },
  metaInfo: {
    meta: [
        {
          name: 'description',
          content: 'Landing page header for boostem.io'
        }
      ],
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
.button-outline {
  border: none;
  border-radius: 8px;
}
</style>
