<template>
  <md-toolbar
      id="toolbar"
      md-elevation="0"
      class="md-absolute md-transparent"
  >
<!--    <div class="alert">-->
<!--      <span>Coming soon to iOS & Android in 2024!</span>-->
<!--    </div>-->

    <div class="md-toolbar-row">
      <div class="md-toolbar-section-end">

            <md-list>
              <md-button
                  href="/"
                  class="md-just-icon md-simple"
                  @click="trackHome()"
              >
                <img src="../../assets/img/home-button-icon.png"/>
              </md-button>
              <md-button
                href="https://www.tiktok.com/@boostemofficial"
                target="_blank"
                class="md-just-icon md-simple"
                @click="trackTikTok()"
              >
                <img src="../../assets/img/tiktok-square-color-icon.png"/>
              </md-button>
              <md-button
                href="https://twitter.com/boostemofficial"
                target="_blank"
                class="md-just-icon md-simple"
                @click="trackTwitter()"
              >
                <img src="../../assets/img/x-social-media-round-icon.png"/>
              </md-button>

            </md-list>
<!--          </div>-->
<!--        </div>-->      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  name: "BoostEmHeader",
  components: {
    // Notifications
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    navbarTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggledClass: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
          document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    trackHome() {
      this.$gtag.event('Navigate_Home', {
        'event_category': 'nav_bar',
        'event_label': 'Go to Home'
      })
    },
    // trackBlog() {
    //   this.$gtag.event('Click', {
    //     'event_category': 'nav_bar',
    //     'event_label': 'Go to Blog'
    //   })
    // },
    trackTikTok() {
      this.$gtag.event('Navigate_TikTok', {
        'event_category': 'nav_bar',
        'event_label': 'Go to TikTok'
      })
    },
    trackTwitter() {
      this.$gtag.event('Navigate_Twitter', {
        'event_category': 'nav_bar',
        'event_label': 'Go to Twitter'
      })
    }

  }
}
</script>
<style scoped>
.alert {
  color: #ffffff;
  background-color: #1F3BEF;
}
</style>