import Vue from "vue";
import Router from "vue-router";

// Boost 'Em imports
import Main from "@/views/Main";
import BoostEmFooter from "@/components/footers/BoostEmFooter";
import BoostEmHeader from "@/components/headers/BoostEmHeader";
import BoostEmSubscribe from "@/views/sections/BoostEmSubscribe";
import BoostEmLicenses from "@/views/sections/BoostEmLicenses";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "boostem",
      components: { default: Main, header: BoostEmHeader, footer: BoostEmFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/licenses",
      name: "cerberus_blog",
      components: { default: BoostEmLicenses, header: BoostEmHeader, footer: BoostEmFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/subscribe",
      name: "boostem_subscribe",
      components: { default: BoostEmSubscribe, header: BoostEmHeader },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
