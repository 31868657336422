<template>
  <div class="main main-raised">
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section section-features-1">
      <div class="container">
<!--        <div class="md-layout">-->
<!--          <div-->
<!--              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"-->
<!--          >-->
<!--            <h2 class="title">-->
<!--              A new way to support your favorite collegiate athletes-->
<!--            </h2>-->
<!--            <h5 class="description">-->
<!--              Introducing a new platform to interact with and support-->
<!--              collegiate athletes. Through Boost 'Em, you can now send-->
<!--              messages & payments directly to the athletes you most care-->
<!--              about & interact with them directly through Boosting them!-->
<!--              Show your support and send funds to receive different-->
<!--              levels of interaction responses based on your level of contribution.-->
<!--              Get to directly engage with the players from your favorite teams-->
<!--              and sports!-->
<!--            </h5>-->
<!--          </div>-->
<!--        </div>-->
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
              icon-color="danger"
              icon="send"
              text-center
            >
              <h4
                slot="title"
                class="info-title"
              >
                Securely Fund Your Athletes
              </h4>
              <p slot="content">
                Send funds to support your favorite athletes through
                safe transactions within the app
                <!--                User data security is a top priority, so you can rest-->
                <!--                assured that your funds and transactions are safe-->
              </p>
            </info-areas>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
                icon-color="info"
                icon="chat"
                text-center
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                Follow Boost 'Em Stats
              </h4>
              <p slot="content">
                Your support matters, and it makes a difference. Track just how
                others are supporting their favorite athletes while
                competing against other fans' Boosts.
              </p>
            </info-areas>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
                icon-color="success"
                icon="verified_user"
                text-center
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                Engage Fully Verified Athletes
              </h4>
              <p slot="content">
                We take care of vetting the athletes, and monitor all participants
                transactions & interactions to ensure fair play
              </p>
            </info-areas>
          </div>
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                icon-color="danger"-->
<!--                icon="fingerprint"-->
<!--                text-center-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Securely Fund Your Athletes-->
<!--              </h4>-->
<!--              <p slot="content">-->
<!--                Send funds to support your favorite athletes through-->
<!--                safe transactions in the app-->
<!--&lt;!&ndash;                User data security is a top priority, so you can rest&ndash;&gt;-->
<!--&lt;!&ndash;                assured that your funds and transactions are safe&ndash;&gt;-->
<!--              </p>-->
<!--            </info-areas>-->
<!--          </div>-->
        </div>
      </div>
<!--      <div class="container">-->
<!--        <div class="md-layout">-->
<!--          <div-->
<!--              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"-->
<!--          >-->
<!--            <h2 class="title">-->
<!--              First-in-Class Data Access-->
<!--            </h2>-->
<!--            <h5 class="description">-->
<!--              Up-to-date financial data for all NYSE & NASDAQ companies <br>-->
<!--              powered by industry-leading APIs-->
<!--            </h5>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="md-layout">-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                info-horizontal-->
<!--                icon-color="info"-->
<!--                icon="group_work"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Integrates Into Your Tools-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  Cerberus' models export into Excel for ease of use post-generation.-->
<!--                  We understand the ubiquity of Excel in finance, and exist to enhance-->
<!--                  your workflows, not interrupt them.-->
<!--                </p>-->
<!--                &lt;!&ndash;                <a href="javascript:void(0)">Find more...</a>&ndash;&gt;-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                info-horizontal-->
<!--                icon-color="danger"-->
<!--                icon="airplay"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Runs on Any Device-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  Although our tool works on all mobile devices, including phones &-->
<!--                  tablets, for ease of use on the go, the full features are best-->
<!--                  experienced on desktop.-->
<!--                </p>-->
<!--                &lt;!&ndash;                <a href="javascript:void(0)">Find more...</a>&ndash;&gt;-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                info-horizontal-->
<!--                icon-color="success"-->
<!--                icon="location_on"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Accessible Anywhere-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  Cloud-based application with ongoing support, accessible-->
<!--                  whenever and wherever you need it.-->
<!--                </p>-->
<!--                &lt;!&ndash;                <a href="javascript:void(0)">Find more...</a>&ndash;&gt;-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <!-- end Features 1 -->

    <!-- Features 2 -->
    <div class="section section-features-1 pt-0">
    <div class="container">
      <div class="md-layout">
        <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
        >
          <h2 class="title">
            Coming soon in 2024 to iOS & Android
          </h2>
          <h5 class="description">
            Use your favorite platform to access our service <br>
            powered through encrypted backend APIs
          </h5>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <info-areas
              info-horizontal
              icon-color="info"
              icon="group_work"
          >
            <h4
                slot="title"
                class="info-title"
            >
              Integrated Funds Processing
            </h4>
            <div
                slot="content"
                class="description"
            >
              <p>
                Boost 'Em uses an industry-leading card payment processor to collect
                and send funds to athletes. Your card and transaction data is
                always secure.
              </p>
              <!--                <a href="javascript:void(0)">Find more...</a>-->
            </div>
          </info-areas>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <info-areas
              info-horizontal
              icon-color="danger"
              icon="airplay"
          >
            <h4
                slot="title"
                class="info-title"
            >
              Available To NCAA Athletes
            </h4>
            <div
                slot="content"
                class="description"
            >
              <p>
                College athletes can now receive payments from their fans
                in exchange for an interaction thanks to the new
                NCAA Name, Image, & Likeness rules.
              </p>
              <!--                <a href="javascript:void(0)">Find more...</a>-->
            </div>
          </info-areas>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <info-areas
              info-horizontal
              icon-color="success"
              icon="location_on"
          >
            <h4
                slot="title"
                class="info-title"
            >
              Accessible Anywhere
            </h4>
            <div
                slot="content"
                class="description"
            >
              <p>
                Cloud-based application accessible whenever and wherever
                you're watching your favorite collegiate athletes compete.
              </p>
              <!--                <a href="javascript:void(0)">Find more...</a>-->
            </div>
          </info-areas>
        </div>
      </div>
    </div>
    <!-- end Features 2 -->

    <!-- Features 3 -->
    <div class="section section-features-3">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="laptop-container">
              <img :src="iphone" alt="iphone with boostEm">
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <!-- Some <br /> tags to push the text to align with the image, you can remove it if you have more text on the right side :-) -->
            <h2 class="title">
              Easy-to-use interface
            </h2>
            <info-areas
                info-horizontal
                icon-color="primary"
                icon="extension"
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                Find Athletes by Name, Sport, or School
              </h4>
              <div
                  slot="content"
                  class="description"
              >
                <p>
                  Easily search through hundreds of athlete members
                </p>
              </div>
            </info-areas>
            <info-areas
                info-horizontal
                icon-color="primary"
                icon="stay_current_portrait"
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                Easy to Use UX
              </h4>
              <div
                  slot="content"
                  class="description"
              >
                <p>
                  Tab-based interface makes it simple to navigate through
                  our app. Check out stats for your favorite teams, see the
                  top Boosted athletes & schools, and update your settings
                  with ease
                </p>
              </div>
            </info-areas>
            <info-areas
                info-horizontal
                icon-color="primary"
                icon="watch_later"
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                Fast & Simple Onboarding
              </h4>
              <div
                  slot="content"
                  class="description"
              >
                <p>
                  A simple registration process enables you to get to Boosting
                  in no time: provide basic identity details, and you'll be able to
                  send funds to athletes, store payment methods, and review transactions
                  through our application
                </p>
              </div>
            </info-areas>
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 3 -->

    <!-- Features 4 -->
    <div class="section section-features-4">
      <div class="container">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-66 md-medium-size-100 mx-auto text-center"
          >
            <h2 class="title">
              This is Boost 'Em!
            </h2>
            <h5 class="description">
              We're so glad you're here! Our mission is to support and democratize
              the funding process for collegiate athletes across the country.
              We've done the hard work of navigating the NCAA Name, Image, & Likeness
              rules & creating a platform to empower athletes to get their
              support directly from fans like you.
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-100 ml-auto">
            <info-areas
                info-horizontal
                icon-color="info"
                icon="code"
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                For Athletes
              </h4>
              <div
                  slot="content"
                  class="description"
              >
                <p>
                  We're enabling NCAA athletes to streamline the way that they
                  interact with and receive funds from supporters, because
                  training and competing should be their focus, not the orchestration of NIL support.
<!--                  We're enabling NCAA athletes to streamline the way that they-->
<!--                  interact with and receive funds from supporters, because-->
<!--                  that orchestration shouldn't be their focus - training & competing should-->
                </p>
              </div>
            </info-areas>
<!--            <info-areas-->
<!--                info-horizontal-->
<!--                icon-color="danger"-->
<!--                icon="format_paint"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                For Retail Investors-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  Divide details about your product or agency work into parts.-->
<!--                  Write a few lines about each one. A paragraph describing a-->
<!--                  feature will be enough.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <div class="phone-container">
              <img :src="boostem" alt="boostem logo">
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100 mr-auto">
            <info-areas
                info-horizontal
                icon-color="primary"
                icon="dashboard"
            >
              <h4
                  slot="title"
                  class="info-title"
              >
                For Fans
              </h4>
              <div
                  slot="content"
                  class="description"
              >
                <p>
                  We're bringing fans closer to their favorite athletes & teams
                  by allowing them to interact with your favorite collegiate all-stars.
<!--                  We're bringing fans closer to their favorite athletes &-->
<!--                  teams. Interact with your favorite collegiate all-stars &-->
<!--                  receive different levels of interaction responses based on the amount-->
<!--                  you send them-->
                </p>
              </div>
            </info-areas>
<!--            <info-areas-->
<!--                info-horizontal-->
<!--                icon-color="success"-->
<!--                icon="view_carousel"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Access to Published IB Analysts' Data-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  Divide details about your product or agency work into parts.-->
<!--                  Write a few lines about each one. A paragraph describing a-->
<!--                  feature will be enough.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 4 -->

    <!-- Features 5 -->
<!--    <div-->
<!--        class="section section-features-5"-->
<!--        :style="features5"-->
<!--    >-->
<!--      <div class="container">-->
<!--        <div class="md-layout">-->
<!--          <div-->
<!--              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"-->
<!--          >-->
<!--            <h2 class="title">-->
<!--              Your modeling simplified-->
<!--            </h2>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="container">-->
<!--        <div class="md-layout">-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="code"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                For Industry Analysts-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="format_paint"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                For Retail Investors-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="dashboard"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Modular Design-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="md-layout">-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="view_carousel"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Access to Published IB Analysts' Data-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="access_time"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Save Time-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--          <div class="md-layout-item md-size-33 md-small-size-100">-->
<!--            <info-areas-->
<!--                text-center-->
<!--                icon="attach_money"-->
<!--            >-->
<!--              <h4-->
<!--                  slot="title"-->
<!--                  class="info-title"-->
<!--              >-->
<!--                Save Money-->
<!--              </h4>-->
<!--              <div-->
<!--                  slot="content"-->
<!--                  class="description"-->
<!--              >-->
<!--                <p>-->
<!--                  The moment you use Material Kit, you know you’ve never felt-->
<!--                  anything like it. With a single use, this powerfull UI Kit-->
<!--                  lets you do more than ever before.-->
<!--                </p>-->
<!--              </div>-->
<!--            </info-areas>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- end Features 5 -->
  </div>
  </div>
</template>

<script>
import { InfoAreas } from "@/components";

export default {
  name: "BoostEmFeatures",
  components: {
    InfoAreas
  },
  data() {
    return {
      // iphone: require("@/assets/img/sections/iphone.png"),
      iphone: require("@/assets/img/iphone_search_page.png"),
      // iphone: require("@/assets/img/iphone_boostEm.png"),
      // iphone2: require("@/assets/img/sections/iphone2.png"),
      boostem: require("@/assets/img/boostDiamond.png"),
      // cerberus: require("@/assets/img/sections/cerberus_dog_icon_01.png"),
      image: require("@/assets/img/bg9.jpg")
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Features segment of landing page for boostem.io'
      }
    ],
  },
};
</script>

<style lang="scss">
.laptop-container {
  padding: 50px 0px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
} @media screen and (max-width: 960px) {
  .laptop-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
