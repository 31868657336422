<template>
  <div class="wrapper">
    <div
        id="main-panel"
        class="main"
    >
      <!-- Headers Section -->
      <div
          id="headers"
          class="cd-section"
      >
        <boost-em-landing />
      </div>

      <!-- Features Section -->
      <div
          id="features"
          class="cd-section"
      >
        <boost-em-features />
      </div>

      <!-- Blogs Section -->
<!--      <div-->
<!--          id="blogs"-->
<!--          class="cd-section"-->
<!--      >-->
<!--        <cerberus-blogposts />-->
<!--      </div>-->

      <!-- Projects Section -->
<!--      <div-->
<!--          id="projects"-->
<!--          class="cd-section"-->
<!--      >-->
<!--        <cerberus-products />-->
<!--        <projects />-->
<!--      </div>-->

      <!-- Pricing Section -->
<!--      <div-->
<!--          id="pricing"-->
<!--          class="cd-section"-->
<!--      >-->
<!--        <pricing />-->
<!--      </div>-->

      <!-- Testimonials Section -->
<!--      <div-->
<!--          id="testimonials"-->
<!--          class="cd-section"-->
<!--      >-->
<!--        <testimonials />-->
<!--      </div>-->

      <!-- ContactUs Section -->
      <div
          id="contacts"
          class="cd-section"
      >
        <boost-em-contact-us/>
      </div>
    </div>
    <nav
        id="cd-vertical-nav"
        class="vertical-nav-active"
    >
      <ul>
        <li>
          <a
              data-number="1"
              @click="scrollToElement('headers'); trackWelcome()"
          >
            <span class="cd-dot" />
            <span class="cd-label">Welcome</span>
          </a>
        </li>
        <li>
          <a
              data-number="2"
              @click="scrollToElement('features'); trackOverview()"
          >
            <span class="cd-dot" />
            <span class="cd-label">Overview</span>
          </a>
        </li>
<!--        <li>-->
<!--          <a-->
<!--              data-number="3"-->
<!--              @click="scrollToElement('blogs'); trackBlog()"-->
<!--          >-->
<!--            <span class="cd-dot" />-->
<!--            <span class="cd-label">Blog</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a-->
<!--              data-number="4"-->
<!--              @click="scrollToElement('projects'); trackFeatures()"-->
<!--          >-->
<!--            <span class="cd-dot" />-->
<!--            <span class="cd-label">Features</span>-->
<!--          </a>-->
<!--        </li>-->
        <li>
          <a
              data-number="3"
              @click="scrollToElement('contacts'); trackContactUs()"
          >
            <span class="cd-dot" />
            <span class="cd-label">Contact Us</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import BoostEmLanding from "@/components/homepage/BoostEmLanding";
import BoostEmFeatures from "@/components/homepage/BoostEmFeatures";
import BoostEmContactUs from "@/components/homepage/BoostEmContactUs";

export default {
  name: "BoostEmMain",
  components: {
    BoostEmContactUs,
    BoostEmFeatures,
    BoostEmLanding,
  },
  mixins: [Mixins.VerticalNav],
  metaInfo: {
    title: 'Boost \'Em',
    meta: [
      {
        name: 'Home | Boost \'Em',
        content: 'Home page for boostem.io'
      }
    ],
  },
  methods: {
    trackWelcome() {
      this.$gtag.event('Click', {
        'event_category': 'side_nav',
        'event_label': 'Go to Welcome'
      })
    },
    trackOverview() {
      this.$gtag.event('Click', {
        'event_category': 'side_nav',
        'event_label': 'Go to Overview'
      })
    },
    trackBlog() {
      this.$gtag.event('Click', {
        'event_category': 'side_nav',
        'event_label': 'Go to Blog'
      })
    },
    trackFeatures() {
      this.$gtag.event('Click', {
        'event_category': 'side_nav',
        'event_label': 'Go to Features'
      })
    },
    trackContactUs() {
      this.$gtag.event('Click', {
        'event_category': 'side_nav',
        'event_label': 'Go to Contact Us'
      })
    },
  }
};
</script>

<style scoped lang="scss">
.vertical-nav-active {
  color: #1F3BEF;
}
</style>