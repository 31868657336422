<template>
  <div
    id="app"
    :class="{ 'nav-open': NavbarStore.showNavbar }"
  >
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
  </div>
</template>
<script>
  export default {
    name: 'BoostEm',
    metaInfo: {
      title: 'Boost \'Em',
    }
  }
</script>
